import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import usePortfolio from "../../hooks/use-portfolio"
import { GetInTouchButton, ShareButton } from "../buttons"
import SharePopup from "../share-popup"

const ContainerPortfolio = () => {
  const [isToggle, setToggle] = useState(false)
  const [display, setDisplay] = useState(false)
  const dataClient = usePortfolio()

  return (
    <div className="w-72 lg:w-full max-w-xl xl:max-w-4xl max-h-screen">
      <h1>/Portfolio</h1>
      <p className="subtitle mt-2">
        This are my latest projects as a frontend developer.
      </p>
      <h4 className="pt-8">Latest Projects</h4>
      <div className="grid lg:grid-cols-2 xl:grid-cols-3 place-items-center gap-8  ">
        {dataClient.map(
          data =>
            data.number < 4 && (
              <a
                href={data.link}
                target="_blank"
                rel="noreferrer"
                className="flex items-center shadow-md px-4 py-2 rounded-md w-[290px] h-[150px] lg:hover:scale-105"
              >
                <GatsbyImage
                  image={data.logo}
                  placeholder="tracedSVG"
                  alt="logo image"
                  className="rounded-full max-h-[80px] min-h-[80px] max-w-[80px] flex-shrink-0"
                />
                <div className="flex flex-col">
                  <p className="pl-4 ">{data.title}</p>
                  <p className="pl-4 text-secondary mt-2  text-xs">
                    {data.techStack}
                  </p>
                </div>
              </a>
            )
        )}
      </div>
      {!display && (
        <button
          onClick={() => setDisplay(true)}
          className="text-secondary underline pt-8"
        >
          Load More
        </button>
      )}

      <div className="grid lg:grid-cols-2  xl:grid-cols-3 place-items-center gap-8 py-8">
        {display &&
          dataClient.map(
            data =>
              data.number >= 4 && (
                <a
                  href={data.link}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center shadow-md px-4 py-2 rounded-md w-[290px] h-[150px] lg:hover:scale-105"
                >
                  <GatsbyImage
                    image={data.logo}
                    placeholder="tracedSVG"
                    alt="logo image"
                    className="rounded-full max-h-[80px] max-w-[80px] flex-shrink-0"
                  />
                  <div className="flex flex-col">
                    <p className="pl-4 ">{data.title}</p>
                    <p className="pl-4 text-secondary mt-2  text-xs">
                      {data.techStack}
                    </p>
                  </div>
                </a>
              )
          )}
      </div>
      <div className="button-text flex justify-between py-4 lg:w-72">
        <div className="my-16">{isToggle === true && <SharePopup />}</div>
        <GetInTouchButton />

        <span
          onClick={() => {
            isToggle === false ? setToggle(true) : setToggle(false)
          }}
        >
          <ShareButton />
        </span>
      </div>
    </div>
  )
}

export default ContainerPortfolio
