import { useStaticQuery, graphql } from "gatsby"

const usePortfolio = () => {
  const hobbies = useStaticQuery(graphql`
    query MyQueryPortafolio {
      allContentfulPortafolio {
        nodes {
          number
          title
          link
          technoStack {
            technoStack
          }
          logo {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return hobbies.allContentfulPortafolio.nodes.map(client => ({
    number: client.number,
    title: client.title,
    link: client.link,
    logo: client.logo.gatsbyImageData,
    techStack: client.technoStack.technoStack,
  }))
}

export default usePortfolio
