import * as React from "react"

import Layout from "../components/layout"
import ContainerPortfolio from "../components/Portfolio"

const PortafolioPage = () => (
  <Layout page="Portfolio">
    <ContainerPortfolio />
  </Layout>
)

export default PortafolioPage
